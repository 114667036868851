export default {
    search: {
        index: 'Search',
    },

    admin: {
        index: 'Admin',

        agencies: {
            index: 'Agencies',
            create: 'Add agency',
            show: '{agency}',
        },

        users: {
            index: 'Portaal gebruikers',
            create: 'Voeg een portaal gebruiker toe',
            show: '{user}',
        },

        backup_servers: {
            index: 'Beheer back-up servers',
            create: 'Voeg een backup server toe',
            show: '{backupServer}',
        },

        server_groups: {
            index: 'Servergroep',
            create: 'Voeg een servergroep toe',
            show: '{serverGroup}',
        },

        services: {
            index: 'Services',
            create: 'Voeg een service toe',
            edit: '{service} configuration',
        },

        server_types: {
            index: 'Servertypes',
            show: '{server_type}',
        },

        roles: {
            index: 'Rollen & rechten',
            create: 'Voeg een rol & recht toe',
            show: '{role}',
            edit: '{role}',
        },

        sla_bundles: {
            index: 'SLA bundles',
        },

        sla_bundle_types: {
            index: 'SLA bundles types',
            create: 'Creëer SLA bundle type',
            show: '{sla_bundle_type}',
        },
    },

    customers: {
        index: 'Klanten',
        create: 'Voeg klant toe',
        show: '{customer}',
        edit: '{customer}',
    },

    monitoring: {
        dashboard: 'Dashboard',
    },

    servers: {
        index: 'Server beheer',
        create: 'Add server',
        show: '{server}',
        edit: '{server}',

        hosts: {
            index: 'Hosts',
            create: 'Voeg host toe',
            show: '{host}',
            edit: '{host}',
        },

        aliases: {
            index: 'Aliassen',
            create: 'Voeg alias toe',
            show: '{alias}',
            edit: '{alias}',
        },

        server_users: {
            index: 'Server gebruikers',
            create: 'Voeg server gebruiker toe',
            show: '{user}',
        },

        ftp_users: {
            index: 'FTP gebruikers',
            create: 'Voeg FTP gebruiker toe',
            show: '{ftpUser}',
            edit: '{ftpUser}',
        },

        mail: {
            index: 'Email',
        },

        server_service: {
            index: 'Services',
            show: 'server_service Show',
            edit: 'server_service Edit',
        },

        ip_addresses: {
            index: 'IP adressen',
            create: 'Voeg IP adres toe',
            show: 'Bewerk ip adres',
        },

        deployments: {
            index: 'Deployments',
            show: 'Deployments detail',
        },

        server_backups: {
            index: 'Back-ups',
        },

        settings: {
            index: 'instellingen',
        },

        server_maintenance: {
            index: 'Server onderhoud',
            show: 'Server onderhoud #{serverMaintenance}',
        },

        centreon_configuration: {
            index: 'Monitoring'
        },

        features: {
            index: 'Functies',
        }
    },

    users: {
        ssh_keys: {
            index: 'Gebruiker SSH Key onderhouding',
            create: 'Voeg SSH key toe',
            show: '{sshKey}',
        },
    },
};
