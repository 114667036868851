export default {
    add:                'Server toevoegen',
    modify:             'Server aanpassen',
    shutdown:           'Server uitschakelen',
    dashboard:          {
        title: 'Dashboard',
    },
    create:             {
        delivery_time: {
            cloud:   'Levertijd Cloud servers: meestal een paar minuten',
            default: 'Selecteer een servertype om de levertijd te bekijken',
            robot:   'Verwachte levertijd: ongeveer {time} minuten',
        },
        disclaimer:    'Alle prijzen incl. 21% BTW. Onze <a href="{href}" class="corebox-link" target="_blank">algemene voorwaarden</a> zijn van toepassing.',
    },
    destroy_confirm: {
        title: 'Server verwijderen',
        content: 'Weet je zeker dat je server `{server}` wilt verwijderen?',
    },
    hosts:              {
        title:        'Hosts',
        add:          'Host toevoegen',
        destroy:      'Host verwijderen',
        destroy_confirm: {
            title: 'Serverhost verwijderen',
            content: 'Weet je zeker dat je host `{host}` wilt verwijderen?',
        },
        show:         'Host',
        ssl_settings: {
            title: 'SSL instellingen',
            hsts:  'Hsts',
        },
        boot:         'Host starten',
        shutdown:     'Host uitschakelen',
        status:       {
            'inactive': 'Inactief',
            'active':   'Actief',
        },
    },
    aliases:            {
        title:   'Aliassen',
        add:     'Alias toevoegen',
        destroy: 'Alias verwijderen',
        show:    'Alias',
        status:  {
            'inactive': 'Inactief',
            'active':   'Actief',
        },
    },
    server_users:       {
        title:   'Servergebruikers',
        add:     'Servergebruiker toevoegen',
        show:    'Servergebruiker',
        saved:   'Servergebruiker opgeslagen',
        updated: 'Servergebruiker bijgewerkt',
        deleted: 'Servergebruiker verwijderd',
        delete:  {
            title:   'Servergebruiker verwijderen',
            content: 'Weet je zeker dat je servergebruiker {user} wilt verwijderen?',
            user:    'Gebruiker verwijderen',
        },
    },
    php_versions: {
        php56: 'PHP 5.6',
        php70: 'PHP 7.0',
        php71: 'PHP 7.1',
        php72: 'PHP 7.2',
        php73: 'PHP 7.3',
        php74: 'PHP 7.4',
        php80: 'PHP 8.0',
        php81: 'PHP 8.1',
        php82: 'PHP 8.2',
        php83: 'PHP 8.3',
    },
    advanced_settings: {
        title: 'Geavanceerde instellingen',
    },
    webserver: {
        title: 'Webserver',
        apache: 'Apache',
        nginx: 'Nginx',
    },
    php_version: {
        title: 'PHP-versie',
    },
    database_version: {
        title: 'Database',
        mysql_57: 'MySQL 5.7',
        mysql_80: 'MySQL 8.0',
    },
    server_os: {
        title: 'Server OS',
        placeholder: 'Ubuntu 18.04 LTS',
    },
    server_location: {
        title: 'Serverlocatie',
        placeholder: 'Finland',
    },
    ftp_users:          {
        title:   'FTP-gebruikers',
        add:     'FTP-gebruiker toevoegen',
        destroy: 'FTP-gebruiker verwijderen',
        show:    'FTP-gebruiker',
        status:  {
            'inactive': 'Inactief',
            'active':   'Actief',
        },
    },
    users:              {
        add:  'Gebruiker toevoegen',
        show: 'Gebruiker',
    },
    email:              {
        title: 'E-mail',

        mail_server: {
            title:   'Mailserver',
            created: 'Mailserver aangemaakt',
            updated: 'Mailserver bijgewerkt',
        },
    },
    services:           {
        title:               'Diensten',
        install:             'Diensten installeren',
        configured_versions: 'Geconfigureerde versies',
    },
    features:           {
        title: 'Functies',
    },
    monitoring:         {
        title: 'Monitoring',
        standard_checks: 'Standaardcontroles'
    },
    ip_addresses:       {
        title:   'IP-adressen',
        add:     'IP-adres toevoegen',
        show:    'IP-adres',
        deleted: 'Servergebruiker verwijderd',
        delete:  {
            title:      'IP-adres verwijderen',
            content:    'Weet je zeker dat je IP-adres {ipAddress} van type {type} wilt verwijderen?',
            ip_address: 'IP-adres verwijderen',
        },
    },
    deployments:        {
        title: 'Deployments',
        show: 'Deploymentdetails',
        created_at: 'Datum/Tijd',
        description: 'Beschrijving (tags)',
        type: 'Type',
        duration: 'Duur',
        status: 'Status',
        details: 'Details',
        statuses: {
            planned: 'gepland',
            failed: 'mislukt',
            running: 'actief',
            canceled: 'geannuleerd',
            success: 'succesvol'
        },
    },
    backups:            {
        title: 'Back-ups',
    },
    settings:           {
        title: 'Instellingen',
        note:  'door de server aan een andere servergroep te koppelen, wordt dit ook verwerkt in het factuuroverzicht.',
    },
    customer:           {
        assign: 'Klant toewijzen',
    },
    change_report:      'Wijzigingsrapport',
    status:             {
        unknown:      'Onbekend',
        running:      'actief',
        deploying:    'deployment actief...',
        'failed deployment': 'deployment mislukt',
        off:          'offline',
        initializing: 'initialiseren',
        starting:     'starten',
    },
    files:              'Bestanden',
    database:           'Database',
    filter_selection:   'Filter selectie',
    ssh_key_management: 'SSH-sleutelbeheer',
    server_user:        'Servergebruiker',
    ssl_options:        {
        none:        'Geen',
        custom:      'Aangepast',
        letsencrypt: 'Let\'s Encrypt',
    },
    ssl:                {
        certificate_info: 'Certificaatinformatie',
        issuer:           'Uitgever',
        domain:           'Domein',
        status_title:     'Status',
        status:           {
            valid:   'Geldig',
            warning: 'Waarschuwing',
        },
    },
    server_installation : {
        title: 'Serverinstallatie',
        go_to_server: 'Ga naar server',
        services_installation: {
            title: 'Installatieservice',
            see_more: 'Bekijk meer 1-klik installatieopties',

            services: {
                vcs: 'Git',
                magento: 'Magento',
                wordpress: 'Wordpress',
                showpare: 'Shopware',
                laravel: 'Laravel',
                akeneo: 'Akeneo',

                vcs_variants: {
                    gitlab: 'Gitlab',
                    github: 'Github',
                    bitbucket: 'Bitbucket',
                    custom: 'Aangepast'
                }
            },

            hostname: {
                title: 'Hostname',
                placeholder: 'user/repository'
            },

            branch: {
                title: 'Branch',
                placeholder: 'master'
            },

            composer: {
                install_deps: 'Installeer composer-afhankelijkheden',
            },

            install: 'Repository installeren',
        }
    },
    maintenance: {
        title: 'Onderhoud',
        allow_maintenance: 'Onderhoud toestaan',
        hours_for_maintenance_launch: 'Uren voor onderhoudsstart',
    },
    server_maintenance: {
        title: 'Serveronderhoud',
        scheduled_at: 'Onderhoudsdatum',
        approved_by: 'Goedgekeurd door',
        change_log: 'Wijzigingslogboek',
        change_log_not_available: 'Niet beschikbaar',
        status: 'Status',

        statuses: {
            failed: 'Mislukt',
            awaiting_approval: 'In afwachting van goedkeuring',
            approved: 'Goedgekeurd',
            dispatched: 'Verzonden',
            first_candidate_finished: 'Eerste kandidaat voltooid',
            finished: 'Voltooid',
            canceled: 'Geannuleerd',
        },

        actions: {
            approve: 'Goedkeuren',
            reject: 'Afwijzen',
        },

        jobs: {
            excluded: 'Uitgesloten',
            planned_at: 'Gepland op',
            started_at: 'Gestart op',
            finished_at: 'Voltooid op',

            statuses: {
                failed: 'Mislukt',
                scheduled: 'Gepland',
                running: 'Actief',
                finished: 'Voltooid',
                canceled: 'Geannuleerd',
            },
        }
    },
    configuration: {
        configuration_changed: {
            title: 'Servertype gewijzigd',
            content: 'De configuratie van het servertype is gewijzigd. De server wordt uitgeschakeld en opnieuw geschaald. Disclaimer: schijfruimte kan niet worden verkleind.',
            submit: 'Opslaan en opnieuw schalen',
        }
    }
};
