export default {
    labels:       {
        active:                'Actief',
        backup_interval:       'Back-up interval',
        agency:                'Agency',
        inactive:              'Inactief',
        name:                  'Naam',
        surname:               'Achternaam',
        email:                 'E-mailadres',
        phone:                 'Telefoon',
        role:                  'Rolnaam',
        account:               {
            active: 'Account actief',
        },
        twofactor:             {
            active: '2FA actief',
        },
        slack:                 {
            id: 'Slack ID',
        },
        notifications:         {
            general:   'Algemene meldingen',
            technical: 'Technische meldingen',
        },
        save:                  'Opslaan',
        expiration_date:       'Vervaldatum',
        public_key:            'Publieke sleutel',
        customer:              'Klant',
        technical_name:        'Technische naam',
        human_readable_name:   'Naam',
        multi_instance:        'Meerdere instanties toestaan',
        type:                  'Type',
        key:                   'Sleutel',
        values:                'Waarden',
        rules:                 'Regels',
        php_version:           'PHP-versie',
        types:                 {
            text:     'Tekst',
            number:   'Nummer',
            checkbox: 'Selectievakje',
            select:   'Selectie',
        },
        include_www:           'Inclusief www',
        ssl_type:              'SSL-type',
        hsts:                  'HSTS',
        host:                  'Host',
        hostname:              'Hostnaam',
        user:                  'Gebruiker',
        server_user:           'Servergebruiker',
        directory:             'Map',
        dns_record:            'DNS-record',
        mailserver:            {
            hostname:         'Mailserver hostnaam',
            postfix_password: 'Postfix-databasewachtwoord',
            rspamd_password:  'Rspamd-wachtwoord',
        },
        database:              'Database',
        username:              'Gebruikersnaam',
        password:              'Wachtwoord',
        password_confirm:      'Bevestig wachtwoord',
        config_rule:           {
            volume: 'Volume',
            cpu:    'CPU',
            memory: 'Geheugen',
            swap:   'Swap',
        },
        pointer:               'Wijst naar',
        redirect_to:           'Doorsturen naar',
        url:                   'URL',
        server_group:          'Servergroep',
        customer_contact_type: 'Contacttype',
        avatar:                'Avatar',
        host_specific:         'Hostspecifiek',
        server:                {
            monitoring: {
                backups:   'Back-ups',
                mailqueue: 'Mailwachtrij',
                server:    'Standaardcontroles (CPU/Geheugen/Schijf/Verkeer)',
                generic:   'Algemeen',
            },
        },
        certificate_key:       'Certificaatsleutel',
        certificate:           'Certificaat',
        nginx_type:            'Nginx-type',
        ipv4:                  'IPv4',
        ipv6:                  'IPv6',
        ftp:                   {
            username:    'FTP-gebruikersnaam',
            server_user: 'Systeemgebruikersnaam',
            password:    'FTP-wachtwoord',
            directory:   'Thuisdirectory',
        },
        ip_address:            'IP-adres',
        server_type:           'Servertype',
        language:              'Taal',
        simplicate_id: 'Simplicate ID',
        price: 'Prijs',
        seats: 'Zitplaatsen',
    },
    placeholders: {
        name:            'Naam',
        surname:         'Achternaam',
        username:        'Gebruikersnaam',
        email:           'E-mailadres',
        role:            'Rol',
        slack:           {
            id: 'Slack ID',
        },
        expiration_date: 'Vervaldatum',
        public_key:      'Publieke sleutel',
        search:          'Zoeken...',
        server:          'Server',
        customer:        'Klant',
        customer_name:   'Klantnaam',
        ip_address:      'IP-adres',
        status:          'Status',
        ftp:             {
            username:  'FTP-gebruikersnaam',
            password:  'FTP-wachtwoord',
            directory: 'Thuisdirectory',
        },
        simplicate_id: 'Simplicate ID',
        price: 'Prijs',
        seats: 'seats',
        agency: 'Agency',
        all: 'Alle',
    },
    select:       {
        start_typing:  'Typ om opties te zoeken...',
        default:       'Selecteer een optie...',
        user:          'Selecteer een gebruiker...',
        ssl:           'Selecteer een SSL-type...',
        host:          'Selecteer een host...',
        type:          'Selecteer een type...',
        memory:        'Selecteer een geheugen...',
        volume:        'Selecteer een volume...',
        cpu:           'Selecteer een CPU...',
        swap:          'Selecteer swap...',
        language:      'Selecteer een taal...',
        configuration: 'Selecteer een configuratie...',
        agency:        'Selecteer een agency...',
        customer:      'Selecteer een klant...',
        new_customer:  'Nieuwe klant: ',
        version:       'Selecteer een versie...',
        server_user:   'Selecteer een servergebruiker...',
        create_server_user:   'Nieuwe servergebruiker aanmaken...',
        create_customer:   'Nieuwe klant aanmaken...',
        create_server_group:   'Nieuwe servergroep aanmaken...',
        server_group:  'Selecteer een servergroep...',
        role:          'Selecteer een rol...',
        service:       'Selecteer een dienst...',
        nginx:         'Selecteer een Nginx-configuratie...',
        ipv4:          'Selecteer een IPv4-record...',
        ipv6:          'Selecteer een IPv6-record...',
        php_version:   'Selecteer PHP-versie...',
        backup_intervals: {
            every_4_hours: 'Elke 4 uur',
            every_hour:    'Elk uur',
        },
    },
    titles:       {
        role:              'Rolinformatie',
        service:           'Service-informatie',
        configuration:     'Configuratie',
        versions:          'Versies',
        basic_settings:    'Basisinstellingen',
        ssl_settings:      'SSL-instellingen',
        redirect_settings: 'Doorstuurinstellingen',
        dkim_settings:     'DKIM-instellingen',
        databases:         'Databases',
        nginx_settings:    'Nginx-instellingen',
        services:          'Diensten',
    },
    features: {
        titles: {
            mysql: 'MySQL',
            elastic: 'Elasticsearch',
            redis: 'Redis',
            other: 'Overig',
            docker: 'Docker',
        },
        labels: {
            elasticsearch: 'Elastic Search versie',
            elastic_five: 'Elastic search 5.X',
            elastic_six: 'Elastic search 6.X',
            elastic_seven: 'Elastic search 7.X',
            email: 'E-mail',
            redis: 'Redis',
            mysql8: 'MySQL 8.x (in plaats van 5.7)',
            docker: 'Docker containers (YML)',
        },
        select: {
            elasticsearch: 'Kies Elastic Search versie...',
        },
        notes: {
            elastic: 'Je kunt maar één Elastic versie tegelijk installeren. Als je meerdere nodig hebt, moet je Docker gebruiken.'
        },
        placeholders: {
            docker: 'elasticsearch:\n' +
                '  \'7.17\':\n' +
                '    memory: 20g\n' +
                '    port_frontend: 9717\n' +
                '    port_backend: 19717\n' +
                '  \'8.4\':\n' +
                '    memory: 10g\n' +
                '    port_frontend: 9804\n' +
                '    port_backend: 19804'
        }
    }
};
