export default {
    agency_management:  {
        title:      'Agency Beheer',
        create_new: 'Nieuwe agency maken',
        add:        'Agency toevoegen',
        refresh:    'Agencies vernieuwen',
        show:       'Agency {agency}',
        details:    'Details van de agency',
    },
    user_management:    {
        title: 'Portaalgebruikers',
        add:   'Portaalgebruiker toevoegen',
    },
    portal:             {
        user: 'Portaalgebruiker',
    },
    billing:            {
        title: 'Facturatie',
    },
    backup_management:  {
        title: 'Backups beheren',
    },
    backup_servers: {
        title: 'Backupservers',
        create: 'Server toevoegen',
    },
    server_groups:      {
        title:  'Servergroepen',
        create: 'Nieuwe servergroep maken',

        name:              'Servergroep',
        number_of_servers: 'Aantal servers',
        project_number:    'Projectnummer',
    },
    features:           {
        title: 'Functies',
    },
    server_types:       {
        title: 'Servertype',
    },
    services:           {
        title:      'Diensten',
        add:        'Dienst toevoegen',
        create_new: 'Nieuwe dienst maken',
        edit:       'Dienst bewerken',
    },
    roles_permissions:  {
        title:          'Rollen & rechten',
        add_role:       'Rol toevoegen',
        add_permission: 'Recht toevoegen',
        role:           {
            all_permissions: 'Alle rechten',
        },
    },
    notifications:      {
        title: 'Notificaties',
    },
    contact_details:    {
        title: 'Contactgegevens',
        request_address_change: 'Adreswijziging aanvragen',
        no_data: 'Geen gegevens beschikbaar',

        phone: 'Telefoon',
        vat_id: 'BTW-nummer',
        chamber_of_commerce: 'Kamer van Koophandel',
        customer_number: 'Klantnummer',
        client_id: 'Klant ID',
        corebox_account_manager: 'CoreBox Account Manager',
    },
    email_addresses: {
        title: 'E-mailadressen',
        general: 'Algemeen',
        invoices: 'Facturen',
        support: 'Support',
    },
    advanced: {
        title: 'Geavanceerd',
        slack: {
            title: 'Slack URL',
        },
        centreon: {
            title: 'Centreon Gebruikersnaam en Wachtwoord',
            url: 'Centreon URL',

            username: 'Gebruikersnaam',
            password: 'Wachtwoord',
        },
    },
    app_authentication: {
        title: 'App Authenticatie',
    },
    download:           {
        app: 'Download de app',
    },
    agency:           {
        regenerate_unique_id: 'Unieke ID opnieuw genereren',

        details: {
            settings: 'Instellingen',
        },
    },
    agency_settings:  {
        title: 'Agencysinstellingen',
    },
    corebox_settings: {
        title: 'CoreBox-instellingen',
    },
    sla_bundles: {
        title: 'Service Level Agreement',
        short_title: 'SLA',
        agency_sla_bundle: 'Agency SLA-bundel - {sla_bundle}',
        no_sla_selected: 'Geen SLA geselecteerd',
        sla_data_message: 'Totaal: {total} Actief: {active} Beschikbaar: {available}',
        available_bundles: {
            title: 'Agency SLA-bundels',
            activate: 'Activeren',
            active: 'Actief',
            minimum_duration: 'Minimaal {duration} maand',
            extends_at: '(Verlengt automatisch op {date})',
        },
        server_gap_exceeded: 'Maximaal aantal actieve servers voor deze bundel bereikt!',
        remove_all_sla: 'Verwijder alle SLA-toewijzingen vóór downgrade',
        remove_some_sla: 'Verwijder {number} SLA-toewijzingen vóór downgrade',
    },
    sla_bundle_types: {
        title: 'SLA-bundeltypes',
        short_title: 'SLA',
        add: 'Toevoegen',
        create: 'SLA-bundeltype maken',
        update: 'SLA-bundeltype - {slaBundleType}',
    },
    servers: {
        title: 'Servers',
    }
};
