import admin       from './admin.js';
import form        from './form.js';
import profile     from './profile.js';
import servers     from './servers.js';
import customers   from './customers.js';
import generics    from './generics.js';
import roles       from './roles.js';
import breadcrumbs from './breadcrumbs.js';
import search      from './search.js';

export default {
	admin,
	form,
	profile,
	servers,
	customers,
	roles,
	breadcrumbs,
	search,

	...generics,
};
