const customers = {
	add:   'Add customer',
	edit:  'Edit customer',
	title: 'Customers',

	contacts: {
		title:               'Contacts',
		delete_confirmation: 'Are you sure you want to delete this contact?',

		type: {
			technical:      'Technical',
			administration: 'Administration',
		},
	},
};

export default customers;
