export default {
	labels:       {
		active:                'Active',
        backup_interval:       'Backup interval',
		agency:                'Agency',
		inactive:              'Inactive',
		name:                  'Name',
		surname:               'Surname',
		email:                 'Emailaddress',
		phone:                 'Phone',
		role:                  'Role name',
		account:               {
			active: 'Account active',
		},
		twofactor:             {
			active: '2FA active',
		},
		slack:                 {
			id: 'Slack ID',
		},
		notifications:         {
			general:   'General notifications',
			technical: 'Technical notifications',
		},
		save:                  'Save',
		expiration_date:       'Expiration date',
		public_key:            'Public key',
		customer:              'Customer',
		technical_name:        'Technical name',
		human_readable_name:   'Name',
		multi_instance:        'Allow Multiple instances',
		type:                  'Type',
		key:                   'Key',
		values:                'Values',
		rules:                 'Rules',
        php_version:           'PHP version',
		types:                 {
			text:     'Text',
			number:   'Number',
			checkbox: 'Checkbox',
			select:   'Select',
		},
		include_www:           'Include www',
		ssl_type:              'SSL type',
		hsts:                  'HSTS',
		host:                  'Host',
		hostname:              'Hostname',
		user:                  'User',
		server_user:           'Server user',
		directory:             'Directory',
		dns_record:            'DNS record',
		mailserver:            {
			hostname:         'Mailserver hostname',
			postfix_password: 'Postfix database password',
			rspamd_password:  'Rspamd password',
		},
		database:              'Database',
		username:              'Username',
		password:              'Password',
		password_confirm:      'Confirm password',
		config_rule:           {
			volume: 'Volume',
			cpu:    'CPU',
			memory: 'Memory',
			swap:   'Swap',
		},
		pointer:               'Pointer to',
		redirect_to:           'Redirect to',
		url:                   'URL',
		server_group:          'Server group',
		customer_contact_type: 'Contact type',
		avatar:                'Avatar',
		host_specific:         'Host specific',
		server:                {
			monitoring: {
				backups:   'Backups',
				mailqueue: 'Mailqueue',
				server:    'Standardchecks (CPU/Memory/Disk/Traffic)',
				generic:   'Generic',
			},
		},
		certificate_key:       'Certificate key',
		certificate:           'Certificate',
		nginx_type:            'Nginx type',
		ipv4:                  'IPv4',
		ipv6:                  'IPv6',
		ftp:                   {
			username:    'FTP username',
			server_user: 'System username',
			password:    'FTP password',
			directory:   'Home dir',
		},
		ip_address:            'IP Address',
		server_type:           'Server Type',
        language:              'Language',
        simplicate_id: 'Simplicate ID',
        price: 'Price',
        seats: 'Seats',
	},
	placeholders: {
		name:            'Name',
		surname:         'Surname',
		username:        'Username',
		email:           'Emailaddress',
		role:            'Role',
		slack:           {
			id: 'Slack ID',
		},
		expiration_date: 'Expiration date',
		public_key:      'Public key',
		search:          'Search...',
		server:          'Server',
		customer:        'Customer',
		customer_name:   'Customer name',
		ip_address:      'IP address',
		status:          'Status',
		ftp:             {
			username:  'FTP username',
			password:  'FTP password',
			directory: 'Home dir',
		},
        simplicate_id: 'Simplicate ID',
        price: 'Price',
        seats: 'Seats',
        agency: 'Agency',
        all: 'All',
	},
	select:       {
		start_typing:  'Type to search through options...',
		default:       'Select a option...',
		user:          'Select a user...',
		ssl:           'Select a SSL type...',
		host:          'Select a host...',
		type:          'Select a type...',
		memory:        'Select a memory...',
		volume:        'Select a volume...',
		cpu:           'Select CPU...',
		swap:          'Select swap...',
		language:      'Select language...',
		configuration: 'Select a configuration...',
		agency:        'Select an agency...',
		customer:      'Select a customer...',
		new_customer:  'New customer: ',
		version:       'Select a version...',
		server_user:   'Select a server user...',
		create_server_user:   'Create new server user...',
		create_customer:   'Create new customer...',
		create_server_group:   'Create new server group...',
		server_group:  'Select a server group...',
		role:          'Select a role...',
		service:       'Select a service...',
		nginx:         'Select a Nginx configuration...',
		ipv4:          'Select IPv4 record...',
		ipv6:          'Select IPv6 record...',
        php_version:   'Select PHP version...',
        backup_intervals: {
            every_4_hours: 'Every 4 hours',
            every_hour:    'Every hour',
        },
	},
	titles:       {
		role:              'Role information',
		service:           'Service information',
		configuration:     'Configuration',
		versions:          'Versions',
		basic_settings:    'Basic settings',
		ssl_settings:      'SSL settings',
		redirect_settings: 'Redirect settings',
		dkim_settings:     'DKIM settings',
		databases:         'Databases',
		nginx_settings:    'Nginx settings',
		services:          'Services',
	},
    features: {
        titles: {
            mysql: 'MySQL',
            elastic: 'Elasticsearch',
            redis: 'Redis',
            other: 'Other',
            docker: 'Docker',
        },
        labels: {
            elasticsearch: 'Elastic Search version',
            elastic_five: 'Elastic search 5.X',
            elastic_six: 'Elastic search 6.X',
            elastic_seven: 'Elastic search 7.X',
            email: 'Email',
            redis: 'Redis',
            mysql8: 'MySQL 8.x (instead of 5.7)',
            docker: 'Docker containers (YML)',
        },
        select: {
            elasticsearch: 'Choose Elastic Search version...',
        },
        notes: {
            elastic: 'You can only install 1 elastic version at the same time. If you need multiple, you should use Docker for that.'
        },
        placeholders: {
            docker: 'elasticsearch:\n' +
                '  \'7.17\':\n' +
                '    memory: 20g\n' +
                '    port_frontend: 9717\n' +
                '    port_backend: 19717\n' +
                '  \'8.4\':\n' +
                '    memory: 10g\n' +
                '    port_frontend: 9804\n' +
                '    port_backend: 19804'
        }
    }
};
