const defaultActions = {
    index: 'Index',
    edit: 'Edit',
    store: 'Store',
    update: 'Update',
    show: 'Show',
    destroy: 'Destroy',
}

const defaultActionsWithEntity = (entity, entities) => {
    return {
        index: `List ${entities}`,
        edit: `Edit ${entity}`,
        store: `Create ${entity}`,
        update: `Update ${entity}`,
        show: `Show ${entity}`,
        destroy: `Delete ${entity}`,
    }
}

export default {
    select_all: 'All rights',
    admin: {
        title: 'Admin permissions',
        permissions: {
            admin: {
                title: 'Admin dashboard',
                ...defaultActions,
                index: 'Allow access',
                agency_management: {
                    title: 'Agency management',
                    ...defaultActionsWithEntity('agency','agencies'),
                },
                portal_users: {
                    title: 'Portal users',
                    ...defaultActionsWithEntity('portal user','portal users'),
                },
                server_groups: {
                    title: 'Server groups',
                    ...defaultActionsWithEntity('server group','server groups'),
                },
                roles: {
                    title: 'Roles',
                    ...defaultActionsWithEntity('role','roles'),
                },
                role_permissions: {
                    title: 'Permissions',
                    ...defaultActionsWithEntity('permission','permissions'),
                },
            },
        },
    },
    dashboard: {
        title: 'Dashboard permissions',
        permissions: {
            dashboard: {
                title: 'Dashboard',
                ...defaultActions,
                index: 'Allow access',
            },
        },
    },
    customers: {
        title: 'Customer permissions',
        permissions: {
            customers: {
                title: 'Customers',
                ...defaultActionsWithEntity('customer','customers'),
            },
        },
    },
    servers: {
        title: 'Server permissions',
        permissions: {
            servers: {
                title: 'Server',
                ...defaultActionsWithEntity('server','servers'),
                hosts: {
                    title: 'Hosts',
                    ...defaultActionsWithEntity('host','hosts'),
                },
                aliases: {
                    title: 'Aliases',
                    ...defaultActionsWithEntity('alias','aliases'),
                },
                server_users: {
                    title: 'Server users',
                    ...defaultActionsWithEntity('user','users'),
                },
                settings: {
                    title: 'Settings',
                    ...defaultActionsWithEntity('server setting','server settings'),
                },
                ftp_users: {
                    title: 'FTP users',
                    ...defaultActionsWithEntity('FTP user','FTP users'),
                },
                email:{
                    title: 'Email',
                    ...defaultActionsWithEntity('email setting','email settings'),
                },
                features:{
                    title: 'Features',
                    ...defaultActionsWithEntity('feature','features'),
                },
                monitoring:{
                    title: 'Monitoring',
                    ...defaultActionsWithEntity('monitoring','monitoring'),
                },
                ip_addresses: {
                    title: 'IP addresses',
                    ...defaultActionsWithEntity('IP address','IP addresses'),
                },
                deployments: {
                    title: 'Deployments',
                    ...defaultActionsWithEntity('deployment','deployments'),
                },
                back_ups: {
                    title: 'Backups',
                    ...defaultActionsWithEntity('backup','backups'),
                },
            }

        },
    },
};
