export default {
    profile: 'Profile',
    profile_information: 'Profile Information',
    security: 'Security',
    name: 'Name',
    email: 'Email',
    profile_image: 'Profile Image',
    password_strength_disclaimer: 'Ensure your account is using a long, random password to stay secure.',
    password: 'Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    language: {
        select_language: 'Select language',
    },
    ssh_key:  {
        title:    'User SSH Key Management',
        subtitle: 'SSH Key',
        add:      'Add SSH key',
        expiration_date: 'The date on which the SSH Key expires',
    },
    sessions: {
        title: 'Browser Sessions',
        description: 'Manage and log out your active sessions on other browsers and devices.',
        description_extended: 'If necessary, you may log out of all of your other browser sessions across all of your devices. Some of ' +
            'your recent sessions are listed below; however, this list may not be exhaustive. If you feel your ' +
            'account has been compromised, you should also update your password.',

        logout: 'Log Out Other Browser Sessions',
        logout_confirm: 'Please enter your password to confirm you would like to log out of ' +
            'your other browser sessions across all of your devices.',
    }
};
