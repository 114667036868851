export default {
    value:                'Value',
    password_set_success: 'Password has been set successfully',
    submit:               'Submit',
    cancel:               'Cancel',
    download:             'Download',
    pending:              'Pending',
    save:                 'Save',
    update:               'Update',
    edit:                 'Edit',
    delete:               'Delete',
    request_download:     'Request download',
    login:                'Login',
    remember_me:          'Remember me',
    forgot_password:      'Forgot password?',
    select_all:           'Select all',
    table:                {
        agency_customer: 'Agency / Customer',
        agency: 'Agency',
        role:            'Role',
        datetime:        'Date/Time',
        size:            'Size',
        services:        'Services',
        name:            'Name',
        technical_name:  'Technical name',
        multi_instance:  'Multiple instances',
        versions:        'Versions',
        server:          'Server',
        customer:        'Customer',
        ip_address:      'IP address',
        status:          'Status',
        username:        'Username',
        server_user:     'Server user',
        host:            'Host',
        alias:           'Alias',
        user:            'User',
        directory:       'Directory',
        public_key:      'Public key',
        expires_at:      'Expires at',
        ip_address_type: 'Type',
        added_at:        'Added at',
        plural:          {
            users:   '{count} user | {count} users',
            servers: '{count} server | {count} servers',
            groups:  '{count} group | {count} groups',
        },
        qty:             {
            user:   'User qty',
            server: 'Server qty',
            groups: 'Group qty',
        },
    },
    active:               'Active',
    copied_to_clipboard:  'Copied to clipboard',
    metrics:              'Metrics',
    settings:             'Settings',
    monitoring:           'Monitoring',
    please_note:          'Please note',
    add:                  'Add',
    none:                 'None',
    servers_count:        '{count} server | {count} servers',
    history:              {
        serveralias:   'Alias',
        serverhost:    'Host',
        serveruser:    'Server user',
        serverftpuser: 'FTP user',
        title:         'History',
        today:         'Today',
        last_week:     'Last week',
        older:         'Older',
        created:       'Created',
        server:        {
            active:      'Active',
            directory:   'Directory',
            username:    'Username',
            url:         'URL',
            type:        'Type',
            hostname:    'Hostname',
            include_www: 'Include www',
            ipv4:        'ipv4',
            ipv6:        'ipv6',
            dkimdns:     'DKIM',
            serveruser:  'Server user',
        },
        changelog:     {
            server:      'Server',
            server_host: 'Server host',
            was:         'was',
            is_now:      'is now',
            empty:       'empty',
            boolean:     {
                on:  'on',
                off: 'off',
            },
            types:       {
                updated: 'Updated',
                created: 'Created',
                deleted: 'Deleted',
            },
        },
    },
    sla_bundle_data: {
        id: 'ID',
        simplicate_id: 'Simplicate ID',
        name: 'Name',
        seats: 'Seats',
        price: 'Price',
        created_at: 'Created at',
        updated_at: 'Updated at',
    },
    server_data: {
        sla_page: {
            hostname: 'Server name',
            sla_since: 'SLA since',
            server_group:{
                name: 'Server group',
            },
        }
    },
    per_month: {
        short: 'p/mo',
        full: 'per month',
    },
    profile_menu: {
        profile: 'Profile',
        documentation: 'Documentation',
        ssh_keys: 'SSH keys',
        logout: 'Logout',
    },
    syncing: {
        started: 'Syncing started',
        completed: 'Syncing completed',
    }
};
