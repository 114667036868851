import {createI18n} from 'vue-i18n';
import locales from '@/i18n/locales.js';

const i18n = createI18n({
    globalInjection: true,
    legacy: false,
    locale: 'en_US',
    fallbackLocale: 'nl_NL',
    messages: locales,
    fallbackWarn: false,
    missingWarn: false
});

export default i18n;
