const search = {
    results_for: '{count} search results for: \'{query}\'',
    title:       'Search',

    tab: {
        servers:   'Servers ({count})',
        customers: 'Customers ({count})',
        hosts:     'Hosts ({count})',
        aliases:   'Aliases ({count})',
        contacts:  'Contacts ({count})',
    },
};

export default search;
