export default {
	agency_management:  {
		title:      'Agency Management',
		create_new: 'Create new agency',
		add:        'Add agency',
		refresh:    'Refresh agencies',
		show:       'Agency {agency}',
		details:    'Agency details',
	},
	user_management:    {
		title: 'Portal Users',
		add:   'Add portal user',
	},
    portal:             {
        user: 'Portal user',
    },
	billing:            {
		title: 'Billing',
	},
	backup_management:  {
		title: 'Backup Management',
	},
    backup_servers: {
      title: 'Backup Servers',
      create: 'Add Server',
    },
	server_groups:      {
		title:  'Server Groups',
		create: 'Create new server group',

		name:              'Server Group',
		number_of_servers: 'Number of servers',
		project_number:    'Projectnumber',
	},
	features:           {
		title: 'Features',
	},
	server_types:       {
		title: 'Server Types',
	},
	services:           {
		title:      'Services',
		add:        'Add service',
		create_new: 'Create new Service',
		edit:       'Edit service',
	},
	roles_permissions:  {
		title:          'Roles & rights',
		add_role:       'Add Role',
		add_permission: 'Add Permission',
		role:           {
			all_permissions: 'All rights',
		},
	},
	notifications:      {
		title: 'Notifications',
	},
	contact_details:    {
		title: 'Contact details',
		request_address_change: 'Request address change',
        no_data: 'No details available',

        phone: 'Phone',
        vat_id: 'VAT ID',
        chamber_of_commerce: 'Chamber of commerce',
        customer_number: 'Customer number',
        client_id: 'Client ID',
        corebox_account_manager: 'CoreBox Account Manager',
	},
    email_addresses: {
        title: 'Email addresses',
        general: 'General',
        invoices: 'Invoices',
        support: 'Support',
    },
    advanced: {
        title: 'Advanced',
        slack: {
            title: 'Slack URL',
        },
        centreon: {
            title: 'Centreon Username and Password',
            url: 'Centreon URL',

            username: 'Username',
            password: 'Password',
        },
    },
	app_authentication: {
		title: 'App Authentication',
	},
	download:           {
		app: 'Download the app',
	},
	agency:           {
		regenerate_unique_id: 'Regenerate unique id',

		details: {
			settings: 'Settings',
		},
	},
	agency_settings:  {
		title: 'Agency Settings',
	},
	corebox_settings: {
		title: 'Corebox Settings',
	},
    sla_bundles: {
        title: 'Service Level Agreement',
        short_title: 'SLA',
        agency_sla_bundle: 'Agency SLA bundle - {sla_bundle}',
        no_sla_selected: 'No SLA',
        sla_data_message: 'Total: {total} Active: {active} Available: {available}',
        available_bundles: {
            title: 'Agency SLA bundles',
            activate: 'Activate',
            active: 'Active',
            minimum_duration: 'Minimum of {duration} month',
            extends_at: '(Automatically extends on {date})',
        },
        server_gap_exceeded: 'Max number of active servers for this bundle reached!',
        remove_all_sla: 'Please remove all SLA assignments before downgrade',
        remove_some_sla: 'Please remove {number} SLA assignments before downgrade',
    },
    sla_bundle_types: {
        title: 'SLA bundles types',
        short_title: 'SLA',
        add: 'Add',
        create: 'Create SLA bundle type',
        update: 'SLA bundle type - {slaBundleType}',
    },
    servers: {
        title: 'Servers',
    }
};
