/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
import Echo  from 'laravel-echo';

import Pusher               from 'pusher-js';
import {trimEnd, trimStart} from 'lodash-es';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// if (import.meta.env.VITE_APP_URL) {
    window.axios.defaults.baseURL = import.meta.env.VITE_APP_URL;
// }

String.prototype.ucfirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ltrim = function (chars = ' ') {
    return trimStart(this, chars);
};

String.prototype.rtrim = function (chars = ' ') {
    return trimEnd(this, chars);
};

String.prototype.ucfirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.euro = function () {
    return parseFloat(this)
        .toLocaleString('nl-NL', {
            style:    'currency',
            currency: 'EUR',
        });
};

Number.prototype.round = function () {
    return Math.round(this);
};

Array.prototype.sortBy = function (key) {
    return this.sort((a, b) => (a[key] > b[key]) ? 1 : -1);
};

const D               = 'Zondag,Maandag,Dinsdag,Woensdag,Donderdag,Vrijdag,Zaterdag'.split(',');
const M               = 'Januari,Februari,Maart,April,Mei,Juni,Juli,Augustus,September,Oktober,November,December'.split(',');
Date.prototype.format = function (format) {
    return format.replace(/a|A|Z|S(SS)?|ss?|mm?|HH?|hh?|D{1,4}|M{1,4}|YY(YY)?|'([^']|'')*'/g, str => {
        let c1    = str.charAt(0);
        const ret = str.charAt(0) === ''
            ? (c1 = 0) || str.slice(1, -1).replace(/''/g, '')
            : str === 'a'
                ? (this.getHours() < 12 ? 'am' : 'pm')
                : str === 'A'
                    ? (this.getHours() < 12 ? 'AM' : 'PM')
                    : str === 'Z'
                        ? (('+' + -this.getTimezoneOffset() / 60).replace(/^\D?(\D)/, '$1').replace(/^(.)(.)$/, '$10$2') + '00')
                        : c1 === 'S'
                            ? this.getMilliseconds()
                            : c1 === 's'
                                ? this.getSeconds()
                                : c1 === 'H'
                                    ? this.getHours()
                                    : c1 === 'h'
                                        ? (this.getHours() % 12) || 12
                                        : (c1 === 'D' && str.length > 2)
                                            ? D[this.getDay()].slice(0, str.length > 3 ? 9 : 3)
                                            : c1 === 'D'
                                                ? this.getDate()
                                                : (c1 === 'M' && str.length > 2)
                                                    ? M[this.getMonth()].slice(0, str.length > 3 ? 9 : 3)
                                                    : c1 === 'm'
                                                        ? this.getMinutes()
                                                        : c1 === 'M'
                                                            ? this.getMonth() + 1
                                                            : ('' + this.getFullYear()).slice(-str.length);
        return c1 && str.length < 4 && ('' + ret).length < str.length
            ? ('00' + ret).slice(-str.length)
            : ret;
    });
};

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster:       'pusher',
    key:               import.meta.env.VITE_PUSHER_APP_KEY,
    cluster:           import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
    wsHost:            import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort:            import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort:           import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS:          (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});
