export default {
	value:            'Waarde',
	cancel:           'Cancel',
	download:         'Download',
	pending:          'Pending',
	save:             'Save',
	request_download: 'Request download',
	table:            {
		datetime: 'Date/Time',
		size:     'Size',
		services: 'Services',
	},
};
